<template>
  <div>
    <v-container>
      <v-row dense no-gutters>
        <v-col cols="6" offset="3">
          <v-form
            ref="form"
            v-model="form.valid"
            lazy-validation>
            <v-card>
              <v-card-text>
                <h1 class="text-h3 text-center py-5">Register</h1>
              </v-card-text>
              <v-card-text>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="10" offset="1" class="text-center">
                      <h3 class="mb-2">Thank you for your interest!</h3>
                      <p>You will get your first <b>5 credits</b> for <b>FREE</b> to generate reports.</p>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="8" offset="2">
                      <v-text-field
                        v-model="form.fields.name"
                        :rules="[$utilities.rules.required]"
                        label="Name"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="8" offset="2">
                      <v-text-field
                        type="email"
                        v-model="form.fields.email"
                        :rules="[$utilities.rules.required, $utilities.rules.isEmail]"
                        label="Email"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-container>
                  <v-row>
                    <v-col class="text-center">
                      <v-btn x-large color="blue-grey darken-4" dark @click="register">
                        Register
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          I have an account! <router-link to="/login">Login</router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'Register.vue',
    data() {
      return {
        form:{
          valid: false,
          fields: {
            name:'',
            email: '',
          },
        },
      }
    },
    methods:{
      async register(){
        let self = this;
        if(this.$refs.form.validate()){
          await this.$store.dispatch("users/register", this.form.fields)
            .then((result) => {
              if(result){
                self.$router.push('/login');
              }
            })
        }
      },
    },
  }
</script>

<style lang="scss">
</style>